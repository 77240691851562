import React from 'react'
import {
  always,
  assocPath,
  complement,
  cond,
  clone,
  mergeRight,
  pathEq,
  pick,
  pipe,
  propEq,
  T,
  path,
} from 'ramda'
import IconHelp from 'emblematic-icons/svg/Help24.svg'
import {
  Anticipation,
  Balance,
  CompanySettings,
  Home,
  FeesCalculator,
  Password,
  PaymentLinks,
  Payments,
  Plans,
  Recipients,
  Settlements,
  Subscriptions,
  Team,
  Transactions,
  Transfers,
  Welcome,
} from './dynamicImports'
import isLinkmeSeller from '../../validation/isLinkmeSeller'
import environment, { chargebackUrl, impersonate } from '../../environment'

import contractsRoutes from './routes/contracts.routes'
import receivablesRegisterRoutes from './routes/receivablesRegister.routes'

/* eslint-disable sort-keys */
const sidebarRoutes = {
  home: {
    component: Home,
    showInSidebar: true,
    showHeaderBack: false,
    path: '/home',
    relevance: 0,
    title: 'pages.home.title',
    value: 'home',
  },
  financial: {
    showInSidebar: true,
    showHeaderBack: false,
    path: 'financial',
    relevance: 1,
    title: 'pages.financial.title',
    value: 'financial',
    sublinks: [
      {
        title: 'pages.balance.title',
        path: '/balance/',
        value: 'balance',
      },
      {
        title: 'pages.transfers.list.title',
        path: '/transfers',
        value: 'transfers',
      },
      {
        title: 'Antecipações',
        path: '/anticipations',
        value: 'anticipations',
      },
      {
        title: 'Liquidações',
        path: '/settlements/:recipientId?',
        value: 'settlements',
      },
    ],
  },
  balance: {
    component: Balance,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    subLinkPath: ['financial', 'balance'],
    relevance: 7,
    path: '/balance/',
    title: 'pages.balance.title',
    value: 'balance',
  },
  settlements: {
    component: Settlements,
    path: '/settlements/:recipientId?',
    showHeaderBack: false,
    showInSidebar: false,
    showSubLink: true,
    subLinkPath: ['financial', 'settlements'],
    title: 'pages.settlements.title',
    value: 'settlements',
  },
  anticipations: {
    component: Anticipation,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    subLinkPath: ['financial', 'anticipations'],
    path: '/anticipations',
    relevance: 2,
    title: 'Antecipações',
    value: 'anticipations',
  },
  anticipation: {
    component: Anticipation,
    showInSidebar: false,
    showHeaderBack: false,
    path: '/anticipation/:id?',
    title: 'pages.anticipation.title',
    value: 'balance',
  },
  balanceDetails: {
    component: Balance,
    showInSidebar: false,
    showHeaderBack: true,
    path: '/balance/:id/details',
    title: 'pages.balance.details_title',
    value: 'balanceDetails',
  },
  payments: {
    component: Payments,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    subLinkPath: ['financial', 'payments'],
    path: '/payments',
    relevance: 2,
    title: 'pages.payments.title',
    value: 'payments',
  },
  sales: {
    showInSidebar: true,
    showHeaderBack: false,
    path: 'sales',
    relevance: 1,
    title: 'pages.sales.title',
    value: 'sales',
    sublinks: [
      {
        title: 'pages.transactions.title',
        path: '/transactions',
        value: 'transactions',
      },
      {
        title: 'pages.payment_links.title',
        path: '/payment-links',
        value: 'payment-links',
      },
    ],
  },
  paymentLinks: {
    component: PaymentLinks,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    subLinkPath: ['sales', 'payment-links'],
    path: '/payment-links',
    relevance: 3,
    title: 'pages.payment_links.title',
    value: 'payment-links',
  },
  configuration: {
    showInSidebar: true,
    showHeaderBack: false,
    path: 'configuration',
    relevance: 8,
    title: 'pages.settings.company.menu',
    value: 'configuration',
    sublinks: [
      {
        title: 'pages.settings.company.account.menu_title',
        path: '/settings/account',
        value: 'account',
        relevance: 1,
      },
      {
        title: 'pages.settings.company.paymentOptions.menu_title',
        path: '/settings/payment-options',
        value: 'payment-options',
        relevance: 5,
      },
      {
        title: 'pages.settings.company.antifraud.menu_title',
        path: '/settings/antifraud',
        value: 'antifraud',
        relevance: 6,
      },
      {
        title: 'pages.settings.company.branding.menu_title',
        path: '/settings/branding',
        value: 'branding',
        relevance: 7,
      },
      {
        title: 'pages.settings.company.notifications.menu_title',
        path: '/settings/notifications',
        value: 'notifications',
        relevance: 8,
      },
      {
        title: 'pages.settings.company.subscriptionInfo.menu_title',
        path: '/settings/subscription',
        value: 'subscription-settings',
        relevance: 9,
      },
    ],
  },
  helpCenter: {
    hasSeparator: true,
    iconRight: <IconHelp width="18" height="18" />,
    showInSidebar: true,
    path: 'https://pagarme.helpjuice.com/pt_BR/pagarme10',
    isExternalLink: true,
    relevance: 9,
    title: 'help_center',
    value: 'helpCenter',
  },
}

export const transactionRoutes = {
  transactions: {
    component: Transactions,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    path: '/transactions',
    subLinkPath: ['sales', 'transactions'],
    relevance: 2,
    title: 'pages.transactions.title',
    value: 'transactions',
  },
  transactionsDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/transactions/:id',
    title: 'pages.transaction.title',
  },
}

export const transferRoutes = {
  transfers: {
    component: Transfers,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    path: '/transfers/:recipientId?',
    subLinkPath: ['financial', 'transfers'],
    relevance: 7,
    title: 'pages.transfers.list.title',
    value: 'transfers',
  },
  transfersRequest: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/transfers/:recipientId/request',
    title: 'pages.transfers.request.title',
  },
  transfersDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/transfers/:recipientId/:transferId',
    title: 'pages.transfers.details.title',
  },
}

export const defaultRoutes = {
  ...sidebarRoutes,
  ...transactionRoutes,
  ...transferRoutes,
  ...receivablesRegisterRoutes,
  ...contractsRoutes,
  accountSettings: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'account'],
    path: '/settings/account',
    relevance: 6,
    showSubLink: true,
    value: 'account',
    title: 'pages.settings.user.menu',
  },
  paymentOptionsSettings: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'payment-options'],
    path: '/settings/payment-options',
    relevance: 5,
    showSubLink: true,
    value: 'payment-options',
    title: 'pages.settings.company.paymentOptions.menu_title',
  },
  changePassword: {
    component: Password,
    showInSidebar: false,
    showHeaderBack: false,
    path: '/change-password',
    title: 'pages.settings.user.menu',
  },
  paymentLinkCreate: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/payment-links/create',
    title: 'pages.payment_links.add_link.title',
  },
  paymentLinksDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/payment-links/:id',
    title: 'pages.payment_link_detail.title',
  },
  feeCalculator: {
    component: FeesCalculator,
    showInSidebar: false,
    showHeaderBack: true,
    path: '/fees-calculator',
    title: 'pages.calculate_fees.title',
    value: 'fees-calculator',
    exact: true,
  },
  welcome: {
    component: Welcome,
    hidden: true,
    path: '/welcome',
    title: 'pages.empty_state.title',
  },
  companySettingsPix: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    path: '/settings/pixinfo',
    relevance: 6,
    title: 'pages.settings.company.menu',
  },
  companySettingsFees: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'fees'],
    path: '/settings/fees',
    relevance: 4,
    title: 'pages.settings.company.fees.menu_title',
    value: 'fees',
    showSubLink: true,
  },
  companySettingsNotifications: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'notifications'],
    path: '/settings/notifications',
    relevance: 8,
    title: 'pages.settings.company.notifications.menu_title',
    showSubLink: true,
    value: 'notifications',
  },
  companySettingsBoleto: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    path: '/settings/boleto',
    relevance: 6,
    title: 'pages.settings.company.menu',
  },
  companySettingsAntifraud: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'antifraud'],
    path: '/settings/antifraud',
    relevance: 6,
    showSubLink: true,
    value: 'antifraud',
    title: 'pages.settings.company.antifraud.menu_title',
  },
  companySettingsBranding: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'branding'],
    path: '/settings/branding',
    relevance: 7,
    showSubLink: true,
    title: 'pages.settings.company.branding.menu_title',
  },
  companySettingsSubscription: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    subLinkPath: ['configuration', 'subscription-settings'],
    path: '/settings/subscription',
    relevance: 6,
    showSubLink: true,
    title: 'pages.settings.company.subscriptionInfo.menu_title',
    value: 'subscription-settings',
  },
  companySettingsDepositAccount: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    path: '/settings/deposit_account',
    relevance: 6,
    title: 'pages.settings.company.menu',
  },
  AnticipationsDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/anticipations/:recipientId/:id/details',
    title: 'pages.anticipation.details.title',
  },
  AnticipationsList: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/anticipations/:recipientId?',
    title: 'pages.anticipation.list.page_title',
  },
}
const createTransactionRoute = {
  transactionCreate: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/transactions/create',
    title: 'pages.transactions.create.title',
  },
}
const recipientsRoutes = {
  recipients: {
    component: Recipients,
    relevance: 5,
    showInSidebar: true,
    showHeaderBack: false,
    path: '/recipients',
    title: 'pages.recipients.title',
    value: 'recipients',
  },
  recipientsAdd: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/add',
    title: 'pages.add_recipient.title',
  },
  recipientsDetailBalance: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:id/balance/:balance_recipient_id?',
    title: 'pages.recipient_detail.title',
  },
  recipientsBalanceDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:id/:recipientId/details',
    title: 'pages.balance.details_title',
  },
  recipientsDetailTransfers: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:recipientId/transfers',
    title: 'pages.recipient_detail.title',
  },
  recipientsDetailSettlements: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:id/settlements/:settlements_recipient_id?',
    title: 'pages.recipient_detail.title',
  },
  recipientsDetailSettings: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:id/settings',
    title: 'pages.recipient_detail.title',
  },
  recipientsDetailInformation: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:id/information',
    title: 'pages.recipient_detail.title',
  },
  recipientsDetailAnticipations: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/recipients/detail/:recipientId/anticipations',
    title: 'pages.recipient_detail.title',
  },
}
const recurrenceRoutes = {
  recurrence: {
    showInSidebar: true,
    showHeaderBack: false,
    path: 'recurrence',
    relevance: 4,
    title: 'pages.recurrence.title',
    value: 'recurrence',
    sublinks: [
      {
        title: 'pages.plans.title',
        path: '/plans',
        value: 'plans',
      },
      {
        title: 'pages.subscriptions.title',
        path: '/subscriptions',
        value: 'subscription',
      },
    ],
  },
  plans: {
    component: Plans,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    subLinkPath: ['recurrence', 'plans'],
    relevance: 7,
    path: '/plans',
    title: 'pages.plans.title',
  },
  subscriptions: {
    component: Subscriptions,
    showInSidebar: false,
    showHeaderBack: false,
    subLinkPath: ['recurrence', 'subscription'],
    showSubLink: true,
    relevance: 7,
    path: '/subscriptions',
    title: 'pages.subscriptions.title',
  },
  createPlans: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/plans/add',
    title: 'pages.plans.title_create',
  },
  plansDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/plans/:id',
    title: 'pages.plans.title_details',
  },
  subscriptionsDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/subscriptions/:id',
    title: 'pages.subscriptions.title_details',
  },
}

const teamRoutes = {
  teamInfo: {
    component: Team,
    showInSidebar: true,
    showHeaderBack: false,
    path: '/team',
    relevance: 6,
    title: 'pages.team.menu_title',
    value: 'team',
  },
}

const settingsRoutes = {
  companySettingsKeys: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    showSubLink: true,
    relevance: 2,
    path: '/settings/api_keys',
    subLinkPath: ['configuration', 'api_keys'],
    title: 'pages.settings.company.card.general.title.menu_title',
    value: 'api_keys',
  },
  companySettingsAddKeys: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    showSubLink: true,
    relevance: 2,
    path: '/settings/add-key',
    subLinkPath: ['configuration', 'api_keys', 'add_api_keys'],
    title: 'pages.settings.company.add_key.title',
    value: 'add_api_keys',
  },
  companySettingsBankAccount: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    showSubLink: true,
    relevance: 3,
    path: '/settings/bank-accounts',
    subLinkPath: ['configuration', 'bank-accounts'],
    title: 'pages.settings.company.bank_account.menu_title',
    value: 'bank-accounts',
  },
  companySettingsFees: {
    component: CompanySettings,
    exact: true,
    hidden: true,
    showSubLink: true,
    relevance: 4,
    path: '/settings/fees',
    subLinkPath: ['configuration', 'fees'],
    title: 'pages.settings.company.fees.menu_title',
    value: 'fees',
  },
}

const paymentsRoutes = {
  paymentsProductPresentation: {
    showInSidebar: false,
    showHeaderBack: true,
    path: '/payments/contextualization',
    title: 'pages.payments.title',
  },
  createPayments: {
    showInSidebar: false,
    showHeaderBack: true,
    path: '/payments/add',
    title: 'pages.payments.create.title',
  },
  showPayments: {
    showInSidebar: false,
    showHeaderBack: true,
    path: '/payments/:id',
    title: 'pages.payments.payment_details.title',
  },
  paymentsResult: {
    showInSidebar: false,
    showHeaderBack: true,
    path: '/payments/:id/result',
    title: 'pages.payments.create.title',
  },
}

/* eslint-enable sort-keys */
const setDefaultRoute = routeName => assocPath([
  routeName, 'defaultRoute',
], true)
const addRoutesOnCondition = (
  condition,
  routesToAdd,
  sublinks = []
) => (currentRoutes) => {
  if (!condition) {
    return currentRoutes
  }
  const routes = mergeRight(currentRoutes, routesToAdd)
  if (sublinks.length) {
    Object.keys(routes).forEach((key) => {
      const sublink = sublinks.find(sub => sub.key === key)
      if (sublink) {
        routes[key].sublinks = routes[key].sublinks
          ? [...routes[key].sublinks, sublink.value]
          : [sublink.value]
      }
    })
  }
  return routes
}
const isCompanyLinkApp = (
  pathEq(['company', 'type'], 'payment_link_app')
)
const isNotCompanyLinkApp = complement(
  isCompanyLinkApp
)

const isLive = environment === 'live' && !impersonate
const companyId = path(['company', 'id'])

const canDoPayments = propEq('canDoPayments', true)
const isAdminOrImpersonated = propEq('isAdminOrImpersonated', true)
const isNotPaymentLink = propEq('isPaymentLink', false)
const isPagarmeAcquirer = propEq('isPagarmeAcquirer', true)

const isNotReadOnly = config => config.user?.permission !== 'read_only'

const buildDefaultRoutes = routes => config => pipe(
  addRoutesOnCondition(isNotPaymentLink(config), settingsRoutes, [{
    key: 'configuration',
    value: {
      path: '/settings/bank-accounts',
      relevance: 3,
      title: 'pages.settings.company.bank_account.menu_title',
      value: 'bank-accounts',
    },
  }]),
  addRoutesOnCondition(true, transactionRoutes),
  addRoutesOnCondition(true, transferRoutes),
  addRoutesOnCondition(isAdminOrImpersonated(config), teamRoutes),
  addRoutesOnCondition(isNotPaymentLink(config)
   && isNotReadOnly(config), settingsRoutes, [
    {
      key: 'configuration',
      value: {
        path: '/settings/api_keys',
        relevance: 2,
        title: 'pages.settings.company.card.general.title.menu_title',
        value: 'api_keys',
      },
    },
    {
      key: 'configuration',
      value: {
        path: '/settings/add-key',
        relevance: 2,
        title: 'pages.settings.company.add_key.title',
        value: 'add_api_keys',
      },
    },
  ]),
  addRoutesOnCondition(isPagarmeAcquirer(config), settingsRoutes, [{
    key: 'configuration',
    value: {
      path: '/settings/fees',
      relevance: 4,
      title: 'pages.settings.company.fees.menu_title',
      value: 'fees',
    },
  }]),
  addRoutesOnCondition(isNotCompanyLinkApp(config), createTransactionRoute),
  addRoutesOnCondition(isNotCompanyLinkApp(config), recurrenceRoutes),
  addRoutesOnCondition(isNotCompanyLinkApp(config), recipientsRoutes),
  addRoutesOnCondition(isLive, [], [{
    key: 'sales',
    value: {
      isExternalLink: true,
      path: `${chargebackUrl}${companyId(config)}`,
      title: 'pages.chargebacks.title',
      value: 'chargebacks',
    },
  }]),
  addRoutesOnCondition(canDoPayments(config), paymentsRoutes, [{
    key: 'financial',
    value: {
      path: '/payments',
      title: 'pages.payments.title',
      value: 'payments',
    },
  }]),
  setDefaultRoute('transactions')
)(clone(routes))

const linkmeSellerRoutes = ['sales']

const getSubRoutesOf = (routes, allRoutes) => {
  const selectedRoutes = [...routes]
  const keys = Object.keys(allRoutes)

  keys.forEach((defaultRouteKey) => {
    const { subLinkPath } = allRoutes[defaultRouteKey]
    if (subLinkPath) {
      routes.forEach((routeKey) => {
        if (subLinkPath.includes(routeKey)) selectedRoutes.push(defaultRouteKey)
      })
    }
  })

  return pick(selectedRoutes, allRoutes)
}

const buildLinkmeSellerRoutes = routes => pipe(
  always(
    mergeRight(
      getSubRoutesOf(linkmeSellerRoutes, routes),
      pick(['changePassword'], routes)
    )
  ),
  setDefaultRoute('paymentLinks')
)

const buildRoutes = pipe(
  cond([
    [isLinkmeSeller, buildLinkmeSellerRoutes(defaultRoutes)],
    [T, buildDefaultRoutes(defaultRoutes)],
  ])
)

export default buildRoutes
